import {
    getProfile,
    getGoMag,
    saveProfile,
    getAffiliateContacts,
    saveAffiliateContact,
    getAffiliateFinancial,
    saveAffiliateFinancial,
    getPagesWithContractBreaches,
    getTechincalData,
    saveTechnicalData,
    getAffiliateMCCData,
    addMccCode,
    removeMccCode,
    getTerminalDocStatus,
    changeAffiliatePassword,
    closeAccount,
    getAffiliateActs,
    uploadActFile,
    downloadUploadedAct,
    getAffiliateDemoProgress,
    getSmartBillStatus,
    submitSmartBillData,
    checkSmartBillAccount,
    getNotificationsList,
    getNotificationsAssoc,
    addNotificationAssoc,
    deleteNotificationAssoc,
    getCouriers,
    getCouriersBoxes,
    addAccountCourier,
    getAccountCouriers,
    getCourierAccount,
    updateCourierPickupPoints,
    deleteCourierAccount,
    getAffiliateAddresses,
    getAffiliateAddress,
    saveAffiliateAddress,
    setCourierAccountFavorite,
    setAffiliateAddressFavorite,
    getReamingDemoDays,
    getChestionareDisponibile,
    sendDataChestionare,
    getBillingData,
    saveBillingData,
    getDateCUI,
    getCourierSettings,
} from '@/api/cont';

import { getPages, deletePage, savePage } from '@/api/setari';

import store from '../../index';
import pickBy from 'lodash.pickby';

export default {
    GetProfile: ({ commit }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getProfile();
                if (res.message == null) return reject(new Error());
                res.message.hasHoliday = !!res.message.hasHoliday;
                res.message.disableMissingProducts = !!res.message.disableMissingProducts;
                store.set('account/informations@profile', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetGoMag: ({ commit }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getGoMag();
                if (res.message == null) return reject(new Error());
                store.set('account/informations@gomag', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    SaveProfile: ({ state }) => {
        const params = store.copy('account/informations@profile');
        params.hasHoliday = +params.hasHoliday;
        return saveProfile(pickBy(params, (value) => value != null && value === value)).then((res) => {
            res.message.hasHoliday = !!res.message.hasHoliday;
            res.message.disableMissingProducts = !!res.message.disableMissingProducts;
            store.set('account/informations@profile', res.message);
        });
    },

    GetAffiliateContacts: ({ state }) =>
        getAffiliateContacts({
            page: state.informations.contacts.page,
        }).then((res) => store.set('account/informations@contacts', res.message)),

    SaveAffiliateContact: () => {
        const params = store.copy('account/informations@contactForm');
        params.status = +params.status;
        return saveAffiliateContact(params).then((res) => store.set('account/informations@contacts', res.message));
    },
    GetAffiliateFinancial: () =>
        getAffiliateFinancial().then((res) => {
            res.message.allowCreditCard = !!res.message.allowCreditCard;
            store.set('account/informations@financial', res.message);
        }),
    SaveAffiliateFinancial: () => {
        const params = store.copy('account/informations@financial');
        params.allowCreditCard = +params.allowCreditCard;
        return saveAffiliateFinancial(params).then((res) => {
            res.message.allowCreditCard = !!res.message.allowCreditCard;
            store.set('account/informations@financial', res.message);
        });
    },
    GetAffiliatePublicPages: ({ state, commit }) => {
        return getPages()
            .then(async (res) => store.set('account/informations@pages.list', res.message))
            .then(() => getPagesWithContractBreaches())
            .then(async (res) => {
                for (const [index, page] of state.informations.pages.list.entries()) {
                    if (res.message.pages.includes(page.pkey.toLowerCase())) {
                        commit('SET_PAGE_BREACH', index);
                    }
                }
            });
    },
    SaveAffiliatePage: ({ state, dispatch }) => {
        const params = {
            pg_n: state.informations.pages.pageDialog.title,
            pkey: state.informations.pages.pageDialog.slug,
            ctnp: state.informations.pages.pageDialog.content,
            id: state.informations.pages.pageDialog.id,
        };
        return savePage(params).then((res) => dispatch('GetAffiliatePublicPages'));
    },
    DeleteAffiliatePage: ({ dispatch }, data) => deletePage(data).then(() => dispatch('GetAffiliatePublicPages')),

    GetAffiliateTechnicalData: () =>
        getTechincalData().then((res) => store.set('account/informations@technical', res.message)),

    SaveAffiliateTechnicalData: ({ state }) =>
        saveTechnicalData(state.informations.technical).then((res) =>
            store.set('account/informations@technical', res.message)
        ),

    GetAffiliateMCCData: ({ state }) =>
        getAffiliateMCCData({
            page: state.informations.mcc.list.page,
            filters: state.informations.mcc.list.filters,
        }).then((res) => {
            res.message.mccCodes.page = +res.message.mccCodes.page;
            store.set('account/informations@mcc.list', res.message.mccCodes);
            store.set('account/informations@mcc.selected', res.message.affiliateCodes);
        }),
    AddAffiliateMCC: ({ state }, mcc) => {
        return addMccCode({
            mcc,
        }).then((res) => store.set('account/informations@mcc.selected', res.message));
    },
    RemoveAffiliateMCC: ({ state }, mcc) => {
        return removeMccCode({
            mcc,
        }).then((res) => store.set('account/informations@mcc.selected', res.message));
    },
    GetAffiliateTerminalDocStatus: () => {
        return getTerminalDocStatus().then((res) =>
            store.set('account/informations@security.terminalDoc', res.message)
        );
    },
    ChangeAffiliatePassword: ({ state }) =>
        changeAffiliatePassword({
            password: state.informations.security.password,
            repeatPassword: state.informations.security.repeatPassword,
        }).then(
            (res) => store.set('account/informations@security.password', ''),
            store.set('account/informations@security.repeatPassword', '')
        ),

    CloseAccount: () => closeAccount(),

    GetAffiliateActs: () => getAffiliateActs().then((res) => store.set('account/informations@acts', res.message)),
    UploadActFile: (context, form) =>
        uploadActFile(form).then((res) => store.set('account/informations@acts', res.message)),
    DownloadUploadedAct: (context, hash) =>
        downloadUploadedAct({
            hash,
        }),
    GetDemoProgress: () => getAffiliateDemoProgress().then((res) => store.set('account/demoProgress', res.message)),
    GetReamingDemoDays: () => getReamingDemoDays().then((res) => store.set('account/reamingDemoDays', res.message)),
    GetChestionareDisponibile: () =>
        getChestionareDisponibile().then((res) => store.set('account/chestionareDisponibile', res.message)),
    GetSmartBillStatus: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getSmartBillStatus();
                if (res.message == null) return reject(new Error());

                if (res.message) {
                    store.set('account/smartbill@form.email', res.message.email);
                    store.set('account/smartbill@form.token', res.message.token);
                    store.set('account/smartbill@form.products_code', res.message.products_code);
                    store.set('account/smartbill@form.estimate_serie', res.message.estimate_serie);
                    store.set('account/smartbill@form.invoice_serie', res.message.invoice_serie);
                    store.set('account/smartbill@form.warehouse', res.message.warehouse);
                    store.set('account/smartbill@form.warehouse_unit', res.message.warehouse_unit);
                    store.set('account/smartbill@form.shipping_service', res.message.shipping_service);
                    store.set('account/smartbill@form.shipping_model', res.message.shipping_model);
                    store.set('account/smartbill@form.issue_payment', res.message.issue_payment);
                    store.set('account/smartbill@form.status', res.message.status);
                }

                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    SubmitSmartBillData: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await submitSmartBillData(state.smartbill.form);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    CheckSmartBillAccount: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await checkSmartBillAccount(state.smartbill.form);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetNotificationsList: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getNotificationsList();
                if (res.message == null) return reject(new Error());
                store.set('account/notifications@list', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetNotificationsAssoc: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getNotificationsAssoc(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    AddNotificationAssoc: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await addNotificationAssoc(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    DeleteNotificationAssoc: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await deleteNotificationAssoc(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetCouriers: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCouriers();
                if (res.message == null) return reject(new Error());
                store.set('account/couriers@couriers_list', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetCouriersBoxes: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCouriersBoxes();
                if (res.message == null) return reject(new Error());
                store.set('account/couriers@boxes', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    AddAccountCourier: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await addAccountCourier(state.couriers.form);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetAccountCouriers: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getAccountCouriers();
                if (res.message == null) return reject(new Error());
                store.set('account/couriers@list', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetCourierAccount: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCourierAccount(data);
                if (res.message == null) return reject(new Error());
                // store.set('account/couriers@list', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    UpdateCourierPickupPoints: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await updateCourierPickupPoints(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    DeleteCourierAccount: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await deleteCourierAccount(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetAffiliateAddresses: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getAffiliateAddresses();
                if (res.message == null) return reject(new Error());
                store.set('account/informations@addresses.list', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetAffiliateAddress: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getAffiliateAddress({
                    address_id: store.get('account/informations@addresses.form.address_id'),
                });
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    SaveAffiliateAddress: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveAffiliateAddress(store.get('account/informations@addresses.form'));
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    SetCourierAccountFavorite: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await setCourierAccountFavorite(data);
                if (res.message == null) return reject(new Error());
                // store.set('account/couriers@list', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    SetAffiliateAddressFavorite: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await setAffiliateAddressFavorite(data);
                if (res.message == null) return reject(new Error());
                // store.set('account/couriers@list', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    SendDataChestionare: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await sendDataChestionare(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetBillingData: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getBillingData(data);
                if (res.message == null) return reject(new Error());

                // Set data
                for (let provider in res.message) {
                    store.set('account/billing@' + provider, res.message[provider]);
                }

                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    SaveBillingData: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveBillingData(state.billing);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetDateCUI: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getDateCUI(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetCourierSettings: ({ state, commit }, courierId) => {
        if (state?.couriers?.couriers_settings?.[courierId]) {
            return new Promise((resolve) => resolve({ message: state.couriers.couriers_settings[courierId] }));
        }
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCourierSettings({ courierId });
                if (res.message == null) return reject(new Error());
                commit('SET_COURIER_SETTINGS', { courierId, settings: res.message });
                return resolve(res);
            } catch (e) {
                return reject(e);
            }
        });
    },
};
