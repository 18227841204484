import pickBy from 'lodash.pickby';
import isEqual from 'lodash.isequal';
import { mapGetters } from 'vuex';
export const filterQueryMixin = {
    data() {
        return {
            filteredQuery: {}
        };
    },
    methods: {
        addFilteredQuery() {
            for (const param in this.filteredQuery) {
                if (typeof this.filters[param] === 'object') this.filters[param].value = this.filteredQuery[param];
            }
        },
        updateQuery(updateStore = true) {
            const currentRoute = this.$router.currentRoute;
            let params = this.$router.currentRoute.params;
            const oldParams = Object.assign({}, params);

            for (const prop in this.filters) {
                switch (true) {
                    case typeof this.filters[prop].type === 'undefined' || this.filters[prop].type === '':
                    case this.filters[prop].type === 'page' && parseInt(this.filters[prop].value) === 0:
                    case this.filters[prop].value == '':
                    case this.filters[prop].value === '' && typeof params[prop] !== 'undefined':
                    case this.filters[prop].type === 'compare' && this.filters[prop].value === 'ee':
                        delete params[prop];
                        continue;

                    default:
                        params[prop] = this.filters[prop].value;
                }
            }
            for (const p in params) {
                if (typeof this.filters[p] === 'undefined' || typeof this.filters[p].value === 'undefined')
                    delete params[p];
            }

            params = pickBy(params);
            if (!isEqual(oldParams, params) || Object.keys(params).length === 0) {
                this.updateAddress(currentRoute.path, params);
                if (updateStore) this.updateStore(currentRoute.path, params);
            }
        },
        updateAddress(path = '', query = {}) {
            if (path === '') return false;
            this.$router
                .replace({
                    path,
                    query
                })
                .catch(() => {});
        },
        updateStore(path, params) {
            this.$store.dispatch('app/UpdateRouteParams', {
                path,
                params
            });
        }
    },
    computed: {
        ...mapGetters({
            fqGetter: 'app/fqGetter'
        }),
        hasFilters() {
            return typeof this.filters !== 'undefined' && typeof this.filterQuery !== 'undefined';
        },
        currentRoute() {
            return this.$router.currentRoute;
        }
    },
    created() {
        if (this.hasFilters) {
            this.filteredQuery = pickBy(this.filterQuery);
            if (Object.keys(this.filteredQuery).length > 0) {
                this.addFilteredQuery();
            }

            if (typeof this.fqGetter[this.currentRoute.path] !== 'undefined') {
                this.filteredQuery = pickBy(this.fqGetter[this.currentRoute.path]);
                this.addFilteredQuery();
                this.updateQuery(false);
            }

            for (const prop in this.filters) {
                if (typeof this.filters[prop].type !== 'undefined' && this.filters[prop].type !== 'input') {
                    this.$watch('filters.' + prop + '.value', () => {
                        this.updateQuery();
                    });
                }
            }
        }
    }
};
