import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import { make } from 'vuex-pathify';

export const initialState = () => ({
    informations: {
        profile: {
            displayName: '',
            accountAge: '',
            closeHour: '',
            closeHourSaturday: '',
            language: '',
            description: '',
            defaultDeliveryTime: 0,
            siteUrl: '',
            holidayMinDate: '',
            holidayMaxDate: '',
            hasHoliday: false,
            disableMissingProducts: false,
        },
        gomag: '',
        addresses: {
            list: [],
            dialog: false,
            countries: [],
            counties: [],
            cities: [],
            form: {
                id: 0,
                type: 'pickup',
                country_id: 1,
                county: '',
                city: '',
                address: '',
                zip: '',
                status: true,
            },
        },
        contacts: {
            list: [],
            page: 1,
            total: 0,
            limit: 25,
        },
        contactForm: {
            name: '',
            phone: '',
            email: '',
            status: 1,
            type: '',
        },
        financial: {
            companyName: '',
            companyCui: '',
            companyEmail: '',
            regCom: '',
            paysTVA: 0,
            acknowledgedTvaFix: 0,
            bankIBAN: '',
            bankName: '',
            allowCreditCard: false,
        },
        pages: {
            list: [],
            withProblems: {},
            pageDialog: {
                id: 0,
                title: '',
                slug: '',
                content: '',
            },
        },
        technical: {
            apiEmitter: '',
        },
        mcc: {
            list: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                filters: {
                    mcc: '',
                    descriere: '',
                    descriere_romana: '',
                },
            },
            selected: [],
        },
        security: {
            password: '',
            repeatPassword: '',
            terminalDoc: false,
        },
        acts: {
            list: [],
        },
    },
    demoProgress: {
        progress: {},
        percentage: 0,
        stepsDone: 0,
    },
    reamingDemoDays: {
        zile: 0,
    },
    chestionareDisponibile: {},
    smartbill: {
        form: {
            email: '',
            token: '',
            products_code: '',
            estimate_serie: '',
            invoice_serie: '',
            warehouse: '',
            warehouse_unit: '',
            shipping_service: 1,
            shipping_model: '',
            issue_payment: 0,
            status: 0,
        },
    },
    notifications: {
        list: [],
    },
    couriers: {
        list: [],
        couriers_list: [],
        boxes: [],
        dialogInsert: false,
        activeStep: 0,
        couriers_settings: {},
        form: {
            id: 0,
            courier_id: '',
            name: '',
            username: '',
            password: '',
            client_id: '',
            awb_format: 'A4',
            service: '',
            epod: false,

            // GITLAB: marketplace/marketplace#61
            baza: 0,
            kgincluse: 0,
            calculateKm: 0,
            extraKm: 0,
            calculateKg: 0,
            extraKg: 0,
            calculateAsig: 0,
            asigSumaStart: 0,
            asigProcentAdd: 0,
            rambursAdd: 0,
            saturdayAdd: 0,
            openCollet: 0,
            openColletPrice: 0,
            limita: 0,
            limitabuc: 0,
            calculateTransport: 0,
            minimumPrice: 0,
            minimumPricebuc: 0,
            add_tva: 0,
            allowCard: 1,
            allowCash: 1,
            calculateByContract: 0,
            enableLockers: 0,
        },
    },
    billing: {
        smartbill: {
            active: 0,
            email: null,
            token: null,
            product_reference: 'products_model',
            invoice_serie: null,
            proforma_serie: null,
            warehouse: null,
            warehouse_unit: null,
            shipping_type: 'product',
            shipping_code: null,
            shipping_box_code: null,
            issue_payment: 0,
            payment_tax: 0,
            save_customer: 0,
            synchronize_stoc: 0,
        },
        fgo: {
            active: 0,
            token1: null,
            token2: null,
            product_reference: 'products_model',
            invoice_serie: null,
            proforma_serie: null,
            warehouse: null,
            shipping_code: null,
            shipping_box_code: null,
            issue_payment: 0,
        },
        oblio: {
            active: 0,
            email: null,
            token: null,
            product_reference: 'products_model',
            invoice_serie: null,
            proforma_serie: null,
            warehouse: null,
            warehouse_unit: 'produs_finit',
            shipping_type: 'product',
            shipping_code: null,
            issue_payment: 0,
            use_stock: 1,
        },
        facturis: {
            active: 0,
            token: null,
            user: null,
            password: null,
            product_reference: 'products_model',
            invoice_serie: null,
            proforma_serie: null,
            warehouse: null,
            shipping_type: 'product',
            shipping_code: null,
            issue_payment: 0,
        },
    },
});

const mutations = {
    ...make.mutations(initialState()),
    ...mutationsFile,
};

const getters = {
    ...make.getters(initialState()),
    ...gettersFile,
};

export default function createAccountStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations,
    };
}
