import { initialState } from './index';
import Vue from 'vue';
export default {
    RESET_STATE: (state) => {
        const iState = initialState();
        for (const prop in state) {
            if (typeof iState[prop] !== 'undefined' && prop !== 'token') {
                state[prop] = iState[prop];
            }
        }
    },
    SET_PAGE_BREACH: (state, index) => {
        Vue.set(state.informations.pages.list[index], 'breach', true);
    },
    SET_COURIER_SETTINGS: (state, { courierId, settings }) => {
        Vue.set(state.couriers.couriers_settings, courierId, settings);
    },
};
