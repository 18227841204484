import fetch from '@/utils/fetch';

// For /setari/index
export function getPages() {
	return fetch({
		url: '/settings/getPages',
		method: 'post'
	});
}

export function deletePage(data) {
	return fetch({
		url: '/settings/deletePage',
		method: 'post',
		data: data
	});
}

export function savePage(data) {
	return fetch({
		url: '/settings/savePage',
		method: 'post',
		data: data
	});
}