<template>
    <div class="app-wrapper" :class="classObj">
        <div class="whitespace"></div>
        <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
        <navbar :class="{ 'notification-bar': notificationBar }"></navbar>
        <sidebar class="sidebar-container"></sidebar>
        <div
            class="main-container"
            :class="{ 'notification-bar': notificationBar }"
            v-loading.fullscreen.lock="bodyLoading"
            element-loading-text="Se incarca"
        >
            <demo-progress class="demo-progress" v-if="isDemo"></demo-progress>
            <questionnaires class="questionnaires" v-if="!isDemo"></questionnaires>
            <app-main></app-main>
        </div>
        <!-- <Features v-if="hasNewFeatures"></Features> -->
        <back-to-top>
            <el-button type="primary" icon="el-icon-arrow-up" size="small" plain></el-button>
        </back-to-top>
        <feedback v-if="isFeedbackActive"></feedback>
        <GDPRModal v-if="userHasInfoLoaded"></GDPRModal>
        <TOSModal v-if="userHasInfoLoaded"></TOSModal>
    </div>
</template>

<script>
import Vue from 'vue';
import BackToTop from 'vue-backtotop';
import VueClipboard from 'vue-clipboard2';
import moment from 'moment';
import { filterQueryMixin } from '@/mixins/filterQuery';

const Navbar = () => import('./components/Navbar');
const Sidebar = () => import('./components/Sidebar/index');
const AppMain = () => import('./components/AppMain');
const DemoProgress = () => import('@/components/DemoProgress');
const GDPRModal = () => import('@/components/GDPRModal');
const Questionnaires = () => import('@/components/Questionnaires');
const TOSModal = () => import('@/components/TosModal');

import createAccountStore from '@/store/modules/account/index';

import ResizeMixin from '@/views/layout/mixin/ResizeHandler';
import { mapGetters } from 'vuex';
// const Features = () =>
// 	import ('@/components/NewFeatures')
const Feedback = () => import('@/components/Feedback');

export default {
    name: 'layout',
    metaInfo() {
        return {
            meta: [{ name: 'robots', content: 'noindex' }],
        };
    },
    components: {
        Navbar,
        Sidebar,
        AppMain,
        Feedback,
        DemoProgress,
        GDPRModal,
        // Features
        Questionnaires,
        TOSModal,
    },
    mixins: [ResizeMixin],
    computed: {
        ...mapGetters({
            isDemo: 'user/isDemo',
            userHasInfoLoaded: 'user/userHasInfoLoaded',
        }),
        notificationBar() {
            return this.$store.state.notifications.bar;
        },
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        bodyLoading() {
            return this.$store.state.app.bodyLoading;
        },
        device() {
            return this.$store.state.app.device;
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile',
            };
        },
        isFeedbackActive() {
            return this.$store.state.app.feedbackActive;
        },
        // hasNewFeatures() {
        // 	return this.$store.state.app.features !== this.$store.state.app.featuresKey
        // }
    },
    watch: {
        'sidebar.opened'(isOpened) {
            if (isOpened && this.device === 'mobile') {
                this.toggleBodyClass('addClass', 'is-mobile');
                this.toggleBodyClass('addClass', 'sidebar-opened');
            } else {
                this.toggleBodyClass('removeClass', 'is-mobile');
                this.toggleBodyClass('removeClass', 'sidebar-opened');
            }
        },
    },
    methods: {
        toggleSideBar(isMobile) {
            this.$store.state.app.sidebar.opened = !isMobile;
        },
        handleClickOutside() {
            this.$store.dispatch('app/CloseSideBar', {
                withoutAnimation: false,
            });
        },
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;

            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        getLanguages() {
            this.$store
                .dispatch('app/GetLanguages')
                .then((response) => {
                    this.$store.commit('app/SET_LANGUAGES', response.message);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getLanguages',
                        },
                    });
                });
        },
    },
    mounted() {
        this.getLanguages();
    },
    beforeCreate() {
        this.$store.registerModule('account', createAccountStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('account');
    },
    created() {
        Vue.use(BackToTop);
        Vue.use(VueClipboard);
        moment.locale(this.$store.getters['app/currentLang']);
        Vue.prototype.$moment = moment;
        Vue.mixin(filterQueryMixin);
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import './src/styles/mixin.scss';

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
}

.main-container.notification-bar {
    top: 50px;
}

.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: fixed;
    z-index: 999;
}

.vue-back-to-top {
    .el-button {
        padding: 7px 10px;
    }
}

@media (max-width: 450px) {
    .vue-back-to-top {
        z-index: 9;
    }
}

.demo-progress {
    padding: 20px 20px 0px 20px;
}
</style>
